<template>
  <section class="intro-x my-5">
    <div class="w-full mb-5">
      <back-button />
    </div>
    <div class="w-full mb-5">
      <h1 class="font-bold text-lg">
        Formulario pedidos
      </h1>
    </div>
    <form @submit="onSubmit">
      <div class="w-full grid grid-cols-1 lg:grid-cols-4 gap-4 mb-5">
        <!-- fecha -->
        <div class="mb-1">
          <label for="requestDate">Fecha</label>
          <!-- <flat-pickr
          id="requestDate"
          v-model="model.requestDate"
          :config="confiFlat"
          class="form-control"
          placeholder="Select date"
          name="requestDate"
          /> -->
          <input type="date" class="form-control" v-model="model.requestDate">
          <messageError :text="errors.requestDate" />
        </div>
        <!-- proveedor -->
        <div class="mb-1">
          <label for="supplierId">Proveedor</label>
          <div class="p-inputgroup">
            <SelectProveedor @change="selectProveedor" id="supplierId" v-model="model.supplierId" ref="autoCompProvedor" />
          </div>
          <messageError :text="errors.supplierId" />
        </div>
      </div>
      <div class="w-full mb-3">
        <DataTable :value="model.articulos" class="p-datatable-sm">
          <Column headerStyle="width:50px;">
            <template #body="{ data }">
              <div class="flex items-center justify-center w-full h-full ">
                <button
                type="button"
                class="bg-red-700 text-white rounded-md btn-remover py-1 px-1"
                @click="onRemoveArticle(data._key)"
                >
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                  </svg>
                </button>
              </div>
            </template>
          </Column>
          <Column header="NOMBRE" field="name">
            <template #body="{ data }">
              <div class="p-inputgroup">
                <AutoComplete
                v-model="data.detail"
                :suggestions="filteredProducts"
                field="name"
                :dropdown="true"
                inputClass="form-control"
                @complete="searchProduct($event)"
                @item-select="selectProduct($event,data._key)"
                >
                  <template #item="{ item }">
                    <div>
                      <span>{{item.name}}</span>
                    </div>
                  </template>
                </AutoComplete>
              </div>
              <div class="p-inputgroup">
                <messageError :text="errors[`articulos[${data._key}].detail`]" />
              </div>
              <div class="p-inputgroup">
                <small class="p-error" v-if="data.detail">
                  {{data.detail&&!model.supplierId ? 'Debe seleccionar un proveedor':''}}
                </small>
              </div>
            </template>
          </Column>
          <!-- <Column header="PRESENTACION">
            <template #body="{ data }">
              <div class="p-inputgroup">
                <select name="presentacion"
                v-model="data.presentation_id"
                class="form-control capitalize"
                @change="selectPresentation($event.target.value,data._key)"
                >
                  <option :value="null">Seleccionar</option>
                  <option
                  v-for="(row,k) in data.presentations"
                  :key="k"
                  :value="row.id"
                  class="capitalize"
                  >
                    {{row.presentation.name}}
                  </option>
                </select>
              </div>
              <messageError :text="errors[`articulos[${data._key}].presentation_id`]" />
            </template>
          </Column> -->
          <Column header="Vlr Unitario">
            <template #body="{ data }">
              <div class="p-inputgroup">
                <input
                v-model="data.amount"
                type="number"
                step="any"
                class="form-control"
                @keyup="onTotalVlr(data._key)"
                >
              </div>
              <messageError :text="errors[`articulos[${data._key}].amount`]" />
            </template>
          </Column>
          <Column header="CANTIDAD">
            <template #body="{ data }">
              <div class="p-inputgroup">
                <input @keyup="onTotalVlr(data._key)" type="number" class="form-control" v-model="data.requestQuantity">
              </div>
              <messageError :text="errors[`articulos[${data._key}].requestQuantity`]" />
            </template>
          </Column>
          <Column header="Total" headerClass="justify-end">
            <template #body="{ data }">
              <div class="p-inputgroup justify-end">
                <!-- <input type="number" class="form-control" v-model="data.amount"> -->
                {{$h.formatCurrency(data.total,2)}}
              </div>
            </template>
          </Column>
          <template #footer>
            <div class="p-inputgroup justify-end">
              Vlr. Total {{$h.formatCurrency(vlrTotal,2)}}
            </div>
          </template>
        </DataTable>
        <messageError :text="errors.articulos" />
        <small class="p-error">{{errorDuplicate}}</small>
      </div>
      <div class="mb-3">
        <button type="button" class="btn btn-primary mr-2" @click="onAddProduct">
          Agregar
        </button>
      </div>
      <div class="w-full my-5 flex justify-center">
        <button type="submit" class="btn btn-primary">
          Crear
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import pedidoBuscarService from '../../../../services/pedidoBuscarService'
// import pedidoEstadoActualizarService from '../../../../services/pedidoEstadoActualizarService'
import orderCrear from '../../../../services/orderCrear'
import articleFindByNameSupplier from '../../../../services/articleFindByNameSupplier'
import { messageConfirm } from '../../../../../../../libs/mensajes'
import { useForm, useField } from 'vee-validate'
import { object, date, array, number, string } from 'yup'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import AutoComplete from 'primevue/autocomplete'
import { useRouter, useRoute } from 'vue-router'
import { onMounted, computed, ref } from 'vue'
import 'flatpickr/dist/flatpickr.css'
import SelectProveedor from '../../../../../../../components/select/proveedores'
import find from 'lodash/find'
import dayjs from 'dayjs'

export default {
  name: 'formPedido',
  components: {
    DataTable,
    Column,
    AutoComplete,
    // flatPickr,
    SelectProveedor
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const isEdit = computed(() => {
      if (route.params.id) return true
      return false
    })
    const confiFlat = ref({
      wrap: true, // set wrap to true only when using 'input-group'
      enableTime: true,
      dateFormat: 'Y-m-d H:i'
    })
    const autoCompProvedor = ref(null)
    const vlrTotal = ref(0)
    const errorDuplicate = ref('')
    const optiosSupliers = ref([])
    const filteredProducts = ref([])
    const validationSchema = object().shape({
      requestDate: date().required().nullable().label('fecha'),
      supplierId: string().required().nullable().label('proveedor'),
      articulos: array()
      .of(
        object().shape({
          detail: object().shape({
            name: string().required().nullable().label('nombre del producto')
          }).required().nullable().label('detalles del producto'),
          presentation_id: number().required().nullable().label('presentación del producto'),
          requestQuantity: number().min(1).required().nullable().label('cantidad del producto'),
          amount: number().min(1).required().nullable().label('vlr unitario del producto'),
          total: number().label('vlr unitario del producto')
        })
      )
      .min(1).label('Producto')
    })
    const { values: model, handleSubmit, handleReset, errors } = useForm({
      validationSchema
    })
    useField('requestDate', null, { initialValue: null })
    useField('supplierId', null, { initialValue: null })
    useField('articulos', null, { initialValue: [{ id: null, article_id: null, detail: null, amount: null, total: null, _key: 0 }] })

    const onTotalVlr = (_key) => {
      model.articulos[_key].total = model.articulos[_key].amount * model.articulos[_key].requestQuantity
      let total = 0
      for (const articulo of model.articulos) {
        total += articulo.total
      }
      vlrTotal.value = total
    }
    const vaidateDuplicateArticle = () => {
      var vali = 0
      for (const v of model.articulos) {
        const itemVali = model.articulos.filter(f => f.article_id === v.article_id && f.presentation_id === v.presentation_id)
        if (itemVali.length > 1) {
          vali = vali + 1
        }
      }
      errorDuplicate.value = ''
      if (vali) {
        errorDuplicate.value = 'Existen artículos con su presentación duplicados'
        return false
      }
      return true
    }
    const onSubmit = handleSubmit((values) => {
      if (!vaidateDuplicateArticle()) return false
      if (isEdit.value) return false
      orderCrear(values).then(({ status, data }) => {
        if (status === 201) {
          // pedidoEstadoActualizarService(data.data.id, { state: 2, justification: 'Pedido solicitado' }).then(result => {
            messageConfirm('Desea crear otro pedido', 'Pedido creado', 'success').then(({ isConfirmed }) => {
              if (isConfirmed) {
                handleReset()
                autoCompProvedor.value.model.value = { cardcode: '', cardname: '' }
                model.articulos = [{ id: null, article_id: null, detail: null, amount: null, total: null, _key: 0 }]
                model.date = dayjs().format('YYYY-MM-DD')
                vlrTotal.value = 0
                errorDuplicate.value = ''
                return false
              }
              router.back()
            })
          // }).catch(err => {
          //   console.error(err.message)
          // })
        }
      }).catch(err => {
        console.error(err.message)
      })
    })
    const onRemoveArticle = (_key) => {
      model.articulos.splice(_key, 1)
      vaidateDuplicateArticle()
    }
    const onAddProduct = () => {
      model.articulos.push({ id: null, detail: null, _key: model.articulos.length })
    }
    const searchProduct = ({ query }) => {
      if (model.supplierId) {
        if (!query.trim() || query.length < 3) {
          filteredProducts.value = []
        } else {
          const queryString = { name: query.trim(), supplierId: model.supplierId }
          return articleFindByNameSupplier(queryString).then(({ data }) => {
            filteredProducts.value = data
          }).catch(err => {
            console.error(err.message)
          })
        }
      }
    }
    const selectPresentation = (value, key) => {
      model.articulos[key].amount = 0
      if (value) {
        const presentationFind = find(model.articulos[key].presentations, ['id', parseInt(value)])
        console.log(presentationFind)
        model.articulos[key].amount = presentationFind.supplier.price
      }
    }
    const selectProduct = ({ value }, _key) => {
      // model.articulos[_key].presentation_id = null
      // model.articulos[_key].presentations = value.presentations
      model.articulos[_key].article_id = value.id
      model.articulos[_key].amount = value.presentations.supplier.price
      model.articulos[_key].presentation_id = value.presentations.id
    }

    const selectProveedor = () => {
      for (const key in model.articulos) {
        model.articulos[key].presentation_id = null
        model.articulos[key].article_id = null
        model.articulos[key].detail = null
        model.articulos[key].presentations = null
        model.articulos[key].amount = null
        model.articulos[key].requestQuantity = null
        model.articulos[key].total = null
      }
    }

    const setOrder = (value) => {
      console.log(value)
      autoCompProvedor.value.model.value = { cardcode: value.supplier.cardcode, cardname: value.supplier.cardname }
      model.supplierId = value.supplierId
    }

    onMounted(() => {
      if (isEdit.value) {
        const { id } = route.params
        pedidoBuscarService(id).then(({ data }) => {
          setOrder(data)
        }).catch(err => {
          console.error(err.message)
        })
      }
      setTimeout(() => {
        handleReset()
        model.requestDate = dayjs().format('YYYY-MM-DD')
      }, 200)
    })
    return {
      model,
      errors,
      optiosSupliers,
      filteredProducts,
      onRemoveArticle,
      onAddProduct,
      onSubmit,
      searchProduct,
      selectProduct,
      isEdit,
      confiFlat,
      errorDuplicate,
      vlrTotal,
      onTotalVlr,
      selectProveedor,
      selectPresentation,
      autoCompProvedor

    }
  }
}
</script>
